import { createEnv } from "@t3-oss/env-nextjs"
import { z } from "zod"

// TODO: Validate schema on build or SKIP_ENV_VALIDATION https://env.t3.gg/docs/nextjs#validate-schema-on-build-(recommended)

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app isn't built with invalid env vars.
   */
  server: {
    NODE_ENV: z.enum(["development", "test", "production"]),
    REVISION: z.string().min(1),
    DD_ENV: z.string().min(1),
    DD_SERVICE: z.string().min(1),
    ALLOW_INSECURE_COOKIES: z.boolean(),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app isn't built with invalid env vars. To expose them to the client, prefix them with `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_DD_VERSION: z.string().min(1),
    NEXT_PUBLIC_DD_APP_ID: z.string().min(1),
    NEXT_PUBLIC_DD_CLIENT_TOKEN: z.string().min(1),
    NEXT_PUBLIC_DD_ENV: z.string().min(1),
    NEXT_PUBLIC_WORKFLOW_STATUS_UPDATE_ENABLED: z.boolean(),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g. middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    REVISION: process.env.REVISION,
    DD_ENV: process.env.DD_ENV,
    DD_SERVICE: process.env.DD_SERVICE,
    ALLOW_INSECURE_COOKIES: process.env.ALLOW_INSECURE_COOKIES == "true",
    NEXT_PUBLIC_DD_VERSION: process.env.NEXT_PUBLIC_DD_VERSION,
    NEXT_PUBLIC_DD_APP_ID: process.env.NEXT_PUBLIC_DD_APP_ID,
    NEXT_PUBLIC_DD_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
    NEXT_PUBLIC_DD_ENV: process.env.NEXT_PUBLIC_DD_ENV,
    NEXT_PUBLIC_WORKFLOW_STATUS_UPDATE_ENABLED:
      process.env.NEXT_PUBLIC_WORKFLOW_STATUS_UPDATE_ENABLED == "true",
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
  /**
   * Makes it so that empty strings are treated as undefined. `SOME_VAR: z.string()` and `SOME_VAR=''` will throw an error.
   */
  emptyStringAsUndefined: true,
})
